
    /deep/ p {
        margin: 0;
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .btn-center-group {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .goods-wrapper {
        .category {
            line-height: 1;
            margin-bottom: 10px;
        }
        .goods-box {
            display: flex;
            .goods-cover {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid #eee;
                box-sizing: border-box;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-right {
                width: 1%;
                flex: 1;
                .goods-title {
                    color: #333;
                    line-height: 18px;
                    transition: all .3s;
                    &:hover {
                        color: #FD4446;
                    }
                }

            }
        }
    }
    /deep/ .goodInfo-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #fd4446;
        }
        .el-dialog__title {
            color: #fd4446;
        }
        .el-dialog__body {
            padding: 0 0 30px;
        }
        .goodInfo-content {
            .info-item {
                .item-title {
                    background: #eee;
                    line-height: 1;
                    height: 60px;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 36px;
                    color: #333;
                    position: relative;
                    display: flex;
                    align-items: center;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        height: 18px;
                        width: 3px;
                        background: #333;
                    }
                }
                .item-content {
                    padding: 20px;
                    .attr-list {
                        .item {
                            margin-top: 10px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .title-input {
        /deep/ .el-input__inner {
            padding-right: 50px;
        }
    }
